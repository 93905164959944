import React, { useState, useEffect, Component } from "react"
import LayoutDoc from "../../../../components/layoutDoc"
import config from "../../../../utils/siteConfig"
import Button from "../../../../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faDatabase, faPlay, faIndustry, faBuilding, faBookOpen, faCode, faCube } from "@fortawesome/free-solid-svg-icons"
import Sidenav from '../../../../components/Organisms/Sidenav/Sidenav'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

export default function Docs({ data }) {
  debugger;
  const page = data.allContentfulZDocs.edges[0].node

  const Bold = ({ children }) => <span className="bold3">{children}</span>
  //const Text = ({ children }) => <p className="align-center">{children}</p>;

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3b">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="bulletPoint">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="d-inline-block">{children}</p>
      ),

    },
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderText: (text) =>
      text
        .replace(/\u2028/g, "")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br />, text])
  }

  //for responsiveness
  const [width, setWindowWidth] = useState(0)
  useEffect(() => {

    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, [])
  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const responsive = {
    webContainer: width > 960
  }

  const {
    id,
    metaData,
    dcRtxt,
  } = page

  return (
    <LayoutDoc
      IsHeaderImageDark={false}
      navColour={"white"}
      activeTab={"workplace"}
      >
      <head>
        <title>{metaData.title}</title>
        <meta charset="utf-8" />
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords.keywords} />
        <meta name="robots" content="noindex" />
      </head>

      <Sidenav activeTab={"workplace"}>

{/*Section Test*/}
<section className="bg-lightgray">
  <div className="sectionFit-container bg-lightgray">
    <div className="row align-items-center">

      <div className="col-lg-6 pb-5">
        <div className="row text-white pb-5">
          <h1 className="align-top h1-medium h1-darkBlue">
            {documentToReactComponents(dcRtxt.json, options)}
          </h1>
        </div>
        <div className="row">

        </div>
        <div className="row pt-4">
          <div class="text-center">
            <Button
              className='btns'
              buttonStyle='btn--primaryPurple'
              buttonSize='btn--large2'
              href={config.navigationURL_contactSales}
            >
              <FormattedMessage id="learn_more" />{" "}
              <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
            </Button>
          </div>
        </div>
      </div>
      <div className="col-lg-6 pb-5 pl-4">
      </div>
    </div>
  </div>
</section>
</Sidenav>

    </LayoutDoc>
  );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZDocs(
        filter: {
        node_locale: { eq: $locale },
        id: {in:["2b8065a0-0ece-5293-93b1-2903bd2820a6", "663c9fa0-7c7c-5f55-b909-e84366c7a890"]}
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                dcRtxt {
                  json
                }
                dcHHd
                dcRtxt2 {
                  json
                }
                dcRtxt3 {
                  json
                }
                dcRtxt4 {
                  json
                }
                dcRtxt5 {
                  json
                }
                dcRtxt6 {
                  json
                }
            }
        }
    }
}
`